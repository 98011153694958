import React from "react";
import Navbar, {Nav} from "../../nav/Navbar";
import Brand from "../../brand/Brand";
import MenuContent from "./MenuContent";


const menuContent = [
    {name: "Home", to: "/"},
    {name: "Services", to: "", dropdownMenu: [
            {name: "All Services", to: "/services"},
            {name: "Development", to: "/services/development"},
            {name: "Digital Marketing", to: "/services/digital-marketing"},
        ]},
    {name: "Our Work", to: "/portfolio"},
    {name: "About", to: "/about"},
    {name: "Contact", to: "/contact"},
    // {name: "Canada Digital", to: "/canadian-digital"},
];
const subMenuContent = [
    {name: "Services", to: "/services"},
    {name: "Development", to: "/services/development"},
];

const DevliftMenu = ({hamburger}) => {

    let $key = 0;
    const getSubMenu = (items) => {
        $key++;
        if (items.dropdownMenu) {
            return (<Nav.Dropdown name={items.name}
                                  key={$key}>{items.dropdownMenu.map(item => getSubMenu(item))}</Nav.Dropdown>);
        }
        return (
            <Nav.Link to={items.to} key={$key}>{items.name}</Nav.Link>
        );
    }


    return (
        <Navbar hamburger={hamburger}>
            <Navbar.Brand to={"/"}>
                <Brand width="120px" />
            </Navbar.Brand>

            <Navbar.Collapse cover="Menu">
                <Nav>
                    {menuContent.map(item => getSubMenu(item))}
                </Nav>
                <MenuContent className="section-margin"/>
            </Navbar.Collapse>
        </Navbar>
    );

}

DevliftMenu.defaultProps = {
    hamburger: false
}
export default DevliftMenu;